import { MethodCode, ModelCode } from '../../@types/Permission';
import { findPermission } from '../../sections/@dashboard/Permissions/utils';
var book = function (user) {
    var hasPermissionCreateBook = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.BOOK, MethodCode.CREATE);
    var hasPermissionListBook = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.BOOK, MethodCode.LIST);
    var hasPermissionViewBook = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.BOOK, MethodCode.VIEW);
    var hasPermissionDeleteBook = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.BOOK, MethodCode.DELETE);
    var hasPermissionEditBook = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.BOOK, MethodCode.EDIT);
    return {
        hasPermissionEditBook: hasPermissionEditBook,
        hasPermissionCreateBook: hasPermissionCreateBook,
        hasPermissionListBook: hasPermissionListBook,
        hasPermissionViewBook: hasPermissionViewBook,
        hasPermissionDeleteBook: hasPermissionDeleteBook,
    };
};
export default book;
